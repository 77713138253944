import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'
import LightboxWrapper from '../../components/lightbox/lightboxwrapper'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const WikiWebsite: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="02-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{ backgroundImage: 'url(../../img/wiki-website-header.jpg)' }}
        ></div>
        <article className="post">
          <h1>Wiki Website</h1>
          <p>
            The new training platform called “wiki website” allow you to manage
            the Longines website independently.
          </p>
          <p>
            Indeed, videos of several minutes on different sections of the
            website are available (homepage, menu, suggestion page, category
            page and product page).
          </p>
          <p>
            You also have the possibility to ask questions but also, to propose
            new ideas for improvements on the wiki and on the website.
          </p>
          <LightboxWrapper>
            <a href={'../../img/wiki.jpg'}>
              <img src={'../../img/wiki.jpg'} className="img-fluid thumbnail" />
            </a>
          </LightboxWrapper>
          <div className="spacer"></div>
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8">
              <div className="alert alert-light text-primary shadow">
                <div className="d-flex align-items-center">
                  <ion-icon
                    name="rocket-outline"
                    style={{ marginRight: '30px', fontSize: '36px' }}
                  ></ion-icon>
                  <div>
                    <h3>Go live ?</h3>
                    <p className="mb-0">Q4 2021</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <blockquote>
            <h4>Need further information?</h4>
            <div className="d-flex flex-column flex-md-row">
              <img
                src={'../../img/dani-heitor.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <p>
                  Please email{' '}
                  <a href="mailto:dani.heitor@longines.com">
                    dani.heitor@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
            <hr />
            <div className="d-flex flex-column flex-md-row">
              <img
                src={'../../img/nils-lehmann.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <p>
                  Please email{' '}
                  <a href="mailto:nils.lehmann@longines.com">
                    nils.lehmann@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
          </blockquote>
        </article>
      </div>
    </Layout>
  )
}

export default WikiWebsite

export const pageQuery = graphql`
  query WikiWebsite_02_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
